const isElementOrParentHidden = (element) => {
  while (element) {
    const style = window.getComputedStyle(element);
    if (style.visibility === 'hidden' || style.display === 'none') {
      return true;
    }
    element = element.parentElement;
  }
  return false;
};

const checkElementIsAvailable = (query) => {
  const element = document.querySelector(query);
  return element && !isElementOrParentHidden(element);
};

// todo: add more DOM-related functions here

export { checkElementIsAvailable };
